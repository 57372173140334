body {
    margin: 0px;
    padding: 0px;

    background-color: rgb(255, 255, 255);

    color: rgb(8, 37, 92);
}

.text-white {
    color: rgb(255, 255, 255);
}

.fabButton {
    position: absolute !important;
    bottom: 5px;
    right: 5px;
    z-index: 1000000;
}


.backgroundJuego {
    min-height: 100vh;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
}

.backgroundJuegoBlanco {
    background-color: rgb(255, 255, 255);
}

.fondoTitulo {
    background-color: rgb(57 157 202);
    color: rgb(255 255 255);
    padding: 5px;
    text-align: center;
}

.background-forms {
    /* background-color: rgb(13 82 115 / 19%); */
    /* border:1px solid #a7b3c159; */
    /* padding: 15px; */
}

.background-forms-secundario {
    /* background-color: rgb(78 13 115 / 19%); */
    /* padding: 15px; */
}

.background-list-cards {
    border:1px solid black;
    background-color: rgba(37, 114, 165, 0.55);
    border-radius: 5px;
    padding: 10px;
}

img {
    border-radius: 10px;
}

/* menú */
.menuDrawer .MuiPaper-root, 
.menuDrawer .MuiPaper-root svg,
.menuDrawer hr {
    background-color: #112f44;
    color: white;
}
.menuDrawer hr {
    background-color: white;
}


/* media query */

@media screen and (max-width: 992px) {
  }
  

  @media screen and (max-width: 600px) {
  }